
import React from 'react';
import './App.scss';
import drRange from './images/drRange.jpeg';

const fetchTestDatas = function () {
  fetch('/api/v1/stats')
    .then(res => res.json())
    .then((response) => { console.log("Test datas response", response); })
    .catch((error) => { console.log("Error while fetching test datas", error); })
}

function App() {
  return (
    <div className="App">
      <header className="App-header">


        <img src={drRange} className="App-logo" alt="logo" />
        <p>
          <b>DOCTOR RANGE</b>
        </p>

        <p className="email-line">stephcurryfansite@gmail.com</p>

      </header>
    </div>
  );
}

export default App;